<template>
  <div class="fourth-div">
    <FourthRightItem
      :title="'Smart Contract Execution'"
      :explanation="'Smart contracts facilitate transparent transactions,compensating annotators and validators from the deposited $ANOT by the task creator, based on predefined criteria.'"
      :count="'6'"
      :iconSrc="image6"
    />
    <FourthLeftItem
      :title="'Data Security'"
      :explanation="'Annot8r ensures privacy and security of the data.'"
      :count="'7'"
      :iconSrc="image7"
    />
  </div>
</template>

<script>
import FourthLeftItem from "@/components/FourthLeftItem.vue";
import FourthRightItem from "@/components/FourthRightItem.vue";

export default {
  components: {
    FourthLeftItem,
    FourthRightItem,
  },
  data() {
    return {
      image6: require("@/assets/images/four-six.png"),
      image7: require("@/assets/images/four-seven.png"),
    };
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.fourth-div {
  height: 100vh;
  padding: 60px;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0px;
  align-items: center;
}
</style>
