<template>
  <div class="seventh-page-main-div">
    <div class="seventh-page">
      <div
        class="seventh-page-background-div width-100 border-box border-radius-48"
      >
        <div class="border-radius-32 seventh-inside-div">
          <form
            action="https://skann.us22.list-manage.com/subscribe/post?u=cbf8427b082ea56f31c16cfb7&amp;id=17dc4f6027&amp;f_id=00fccbe1f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="validate"
            target="_blank"
          >
            <div
              class="column-gap-32px-div align-center"
              style="text-align: center"
            >
              <h2
                class="font-family-oxanium color-ffffff font-size-4vw font-weight-900"
              >
                Join Our Waitlist
              </h2>
              <p
                class="font-family-plus-jakarta font-size-1vw color-c7cbd1 font-weight-500 line-height-1-8 width-90"
              >
                Don’t miss out! Join our waitlist and be the first to hear about
                Annot8r and
                <span class="color-ffffff font-weight-600">$ANOT</span> Token.
              </p>

              <div class="email-button-div border-radius-48">
                <div class="mc-field-group">
                  <input
                    type="email"
                    name="EMAIL"
                    class="required email input"
                    id="mce-EMAIL"
                    required="true"
                    placeholder="Your Email Address"
                  />
                </div>
                <div id="mce-responses" class="clear foot">
                  <div
                    class="response"
                    id="mce-error-response"
                    style="display: none"
                  ></div>
                  <div
                    class="response"
                    id="mce-success-response"
                    style="display: none"
                  ></div>
                </div>
                <div
                  aria-hidden="true"
                  style="position: absolute; left: -5000px"
                >
                  <input
                    type="text"
                    name="b_cbf8427b082ea56f31c16cfb7_17dc4f6027"
                    tabindex="-1"
                    value=""
                  />
                </div>

                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  class="button join-button"
                  value="JOIN"
                  style="background-color: #4b54f9; color: #ffffff"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  <div class="seventh-mobile">
    <div class="mobile-inside">
      <form
        action="https://skann.us22.list-manage.com/subscribe/post?u=cbf8427b082ea56f31c16cfb7&amp;id=17dc4f6027&amp;f_id=00fccbe1f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
      >
        <div>
          <h2
            class="font-family-oxanium color-ffffff font-size-4vw font-weight-900"
            style="text-align: center"
          >
            Join Our Waitlist
          </h2>
          <p
            class="font-family-plus-jakarta font-size-1vw color-c7cbd1 font-weight-500 line-height-1-8 width-90"
            style="padding: 32px 0; text-align: center"
          >
            Don’t miss out! Join our waitlist and be the first to hear about
            Annot8r and
            <span class="color-ffffff font-weight-600">$ANOT</span> Token.
          </p>

          <div class="email-button-div border-radius-48">
            <div class="mc-field-group">
              <input
                type="email"
                name="EMAIL"
                class="required email input"
                id="mce-EMAIL"
                required="true"
                placeholder="Your Email Address"
              />
            </div>
            <div id="mce-responses" class="clear foot">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <div aria-hidden="true" style="position: absolute; left: -5000px">
              <input
                type="text"
                name="b_cbf8427b082ea56f31c16cfb7_17dc4f6027"
                tabindex="-1"
                value=""
              />
            </div>
            <input
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button join-button"
              value="JOIN"
              style="background-color: #4b54f9; color: #ffffff"
            />
          </div>
        </div>
      </form>
      <MobileFooter class="mobile-footer" />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
import MobileFooter from "@/components/MobileFooter.vue";
export default {
  components: {
    Footer,
    MobileFooter,
  },
  data() {},
};
</script>

<style scoped>
@import url(../assets/css/styles.css);
.seventh-page-main-div {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  align-items: center;
  justify-content: center;
}
.seventh-page {
  height: 75vh;
  width: 100%;
  padding: 40px 24px;
  display: grid;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.seventh-page-background-div {
  background-image: url(../assets/images/seventh-page-image.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  width: 95vw;
  height: 75vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  padding: 3%;
}
.seventh-inside-div {
  width: 50%;
  height: 65vh;
  background: rgba(15, 17, 42, 0.75);
  backdrop-filter: blur(13px);
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 5%;
}
.email-button-div {
  width: 100%;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 24px;
  align-items: center;
  box-sizing: border-box;
  padding: 24px 0 24px 24px;
  position: relative;
}
.join-button {
  width: 190px;
  height: 60px;
  box-sizing: border-box;
  max-width: 100%;
  width: 10vw;
  position: absolute;
  z-index: 4;
  right: 0;
}
.input {
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-family: "Plus Jakarta Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  width: 64%;
  bottom: 20px;
}
.input::placeholder {
  color: #c7cbd1;
}
.input:focus {
  outline: none;
}
.seventh-mobile {
  display: none;
}
@media (max-width: 900px) {
  .seventh-page-main-div {
    display: none;
  }
  .seventh-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: end;
  }
  .mobile-inside {
    height: 90vh;
    width: 100%;
    box-sizing: border-box;
    background-image: url(../assets/images/seventh-image-mobile.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    padding: 24px;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    position: relative;
  }
  .join-button {
    height: 50px;
    box-sizing: border-box;
    width: 120px;
  }
  .input {
    font-size: 12px;
  }
  .button-mobile-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
  }
  .mobile-footer {
    position: absolute;
    bottom: 24px;
  }
}
</style>
