<template>
  <div class="wrapper relative">
    <div
      class="navbar navbar-new row-gap-space-between width-100 z-index-100 border-box"
    >
      <img
        :src="isBlack ? srcBlack : srcWhite"
        class="logo pointer"
        @click="reloadPage"
      />

      <div class="row-gap-16px-div">
        <button
          class="button color-ffffff background-blur"
          :class="{
            'button-black-small': isBlack,
            'button-white-small': !isBlack,
          }"
          @click="scrollToLastSection"
        >
          Join our waitlist
        </button>
        <button
          class="button color-ffffff background-blur"
          :class="{
            'button-black-small': isBlack,
            'button-white-small': !isBlack,
          }"
          @click="toggleDocsOverlay"
        >
          docs
        </button>
        <div v-if="showDocsOverlay" class="docs-overlay">
          <button
            class="button color-ffffff background-blur"
            :class="{
              'button-black-small': isBlack,
              'button-white-small': !isBlack,
            }"
            @click="openWhitepaper"
          >
            Whitepaper
          </button>

          <button
            class="button color-ffffff background-blur"
            :class="{
              'button-black-small': isBlack,
              'button-white-small': !isBlack,
            }"
            @click="openPitchDeck"
          >
            Pitch Deck
          </button>
        </div>
      </div>
    </div>

    <div
      class="navbar-mobile navbar-new row-gap-space-between width-100 z-index-100 border-box"
    >
      <img
        :src="isBlack ? srcBlack : srcWhite"
        class="logo pointer"
        @click="reloadPage"
      />
    </div>
    <div class="container">
      <section class="one">
        <FirstPage
          @scroll-to-last-section="scrollToLastSection"
          @scroll-to-second-section="scrollToSecondSection"
        />
      </section>

      <section class="two">
        <SecondPage />
      </section>

      <section class="three">
        <ThirdPage @scroll-to-last-section="scrollToLastSection" />
      </section>

      <section class="four">
        <FourthPageOne />
      </section>

      <section class="five">
        <FourthPageTwo />
      </section>

      <section class="six">
        <FourthPageThree />
      </section>

      <section class="seven">
        <FourthPageFour />
      </section>

      <section class="eight">
        <FifthPage />
      </section>

      <section class="nine">
        <SixthPage />
      </section>

      <section class="ten">
        <SeventhPage />
      </section>
    </div>
  </div>
</template>

<script>
import FifthPage from "./views/FifthPage.vue";
import FirstPage from "./views/FirstPage.vue";
import FourthPageFour from "./views/FourthPageFour.vue";
import FourthPageOne from "./views/FourthPageOne.vue";
import FourthPageThree from "./views/FourthPageThree.vue";
import FourthPageTwo from "./views/FourthPageTwo.vue";
import SecondPage from "./views/SecondPage.vue";
import SeventhPage from "./views/SeventhPage.vue";
import SixthPage from "./views/SixthPage.vue";
import ThirdPage from "./views/ThirdPage.vue";

export default {
  name: "App",
  components: {
    FirstPage,
    SecondPage,
    ThirdPage,
    FifthPage,
    SixthPage,
    SeventhPage,
    FourthPageOne,
    FourthPageTwo,
    FourthPageThree,
    FourthPageFour,
  },
  data() {
    return {
      showDocsOverlay: false,
      isBlack: false,
      srcWhite: require("../src/assets/images/logo-white.png"),
      srcBlack: require("../src/assets/images/logo-black.svg"),
      currentSectionIndex: 0,
    };
  },
  methods: {
    openWhitepaper() {
      const pdfUrl = "/docs/whitepaper.pdf";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showDocsOverlay = false;
    },
    openPitchDeck() {
      const pdfUrl = "/docs/pitch.pdf";
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.showDocsOverlay = false;
    },
    toggleDocsOverlay() {
      this.showDocsOverlay = !this.showDocsOverlay;
    },
    reloadPage() {
      location.reload();
    },
    scrollToLastSection() {
      let app = this;
      const sections = document.querySelectorAll("section");
      const lastSectionIndex = sections.length - 1;
      this.currentSectionIndex = lastSectionIndex;
      this.scrollToSection(lastSectionIndex);
      app.isBlack = true;
    },
    scrollToSecondSection() {
      let app = this;
      const sections = document.querySelectorAll("section");
      const secondSectionIndex = 1;
      this.currentSectionIndex = secondSectionIndex;
      this.scrollToSection(secondSectionIndex);
      app.isBlack = true;
    },
    scrollToSection(index) {
      let app = this;
      const sections = document.querySelectorAll("section");
      sections[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      app.isBlack = true;
    },
  },
  mounted() {
    const container = document.querySelector(".container");
    const sections = document.querySelectorAll("section");
    let currentSectionIndex = 0;
    const scrollSpeed = 3000;
    let isScrolling = false;
    let app = this;

    function scrollToSection(index) {
      sections[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    container.addEventListener("scroll", function (event) {
      event.preventDefault();

      if (isScrolling) return;
      isScrolling = true;

      const delta = Math.sign(event.deltaY);
      const targetIndex = currentSectionIndex + delta;

      if (targetIndex >= 0 && targetIndex < sections.length) {
        currentSectionIndex = targetIndex;
        scrollToSection(currentSectionIndex);
        app.isBlack = true;
        console.log(app.isBlack);
      } else if (delta < 0 && currentSectionIndex > 0) {
        currentSectionIndex--;
        app.isBlack = true;
        console.log(app.isBlack);
        scrollToSection(currentSectionIndex);
      } else {
        app.isBlack = true;
        console.log(app.isBlack);
      }

      setTimeout(() => {
        isScrolling = false;
      }, scrollSpeed);
    });

    container.addEventListener("scroll", function () {
      if (container.scrollTop === 0) {
        app.isBlack = false;
        console.log(app.isBlack);
      }
    });

    setTimeout(() => {
      const navbar = document.querySelector(".navbar");
      navbar.style.transition = "transform 1s ease-in-out";
      navbar.style.transform = "translateY(0)";
    }, 0);
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Oxanium:wght@200..800&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");
#app,
body {
  background-color: #eaeaea;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100vh;
}
.wrapper {
  min-height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox için */
  -ms-overflow-style: none; /* Internet Explorer 11 için */
}
.container::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, Opera) için */
}
section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: start;
}
.navbar {
  transition: transform 1s ease-in-out;
  transform: translateY(-50px);
}
.logo {
  height: 24px;
}
.navbar-new {
  padding: 10px 50px 0 50px;
  position: absolute;
}
.button-white-small {
  padding: 8px 16px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-out;
}
.button-black-small {
  padding: 8px 16px;
  font-size: 12px;
  background-color: #d4d4d7;
  transition: all 0.3s ease-out;
  color: #0f112a;
}
.navbar-mobile {
  display: none;
}
.docs-overlay {
  position: absolute;
  top: 100%;
  right: 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;

  z-index: 200;
  align-items: end;
}
.button-docs {
  padding: 8px 16px;
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  transition: all 0.3s ease-out;
}
.button-docs:hover {
  font-size: 12px;
  background-color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 900px) {
  .navbar {
    display: none;
  }
  .navbar-mobile {
    display: flex;
  }
  .logo {
    height: 20px;
  }
  .navbar-new {
    padding: 24px;
    position: absolute;
  }
}
</style>
