<template>
  <div class="fourth-right-item-div">
    <div class="fourth-item-text-div column-gap-16px-div">
      <p
        class="font-family-oxanium font-weight-900 color-0f112a font-size-1-4vw"
      >
        {{ title }}
      </p>
      <p
        class="font-family-plus-jakarta font-size-1vw color-6a7386 font-weight-500 line-height-1-8"
      >
        {{ explanation }}
      </p>
    </div>
    <div class="relative">
      <img :src="iconSrc" class="fourth-image" />
      <div
        class="number-div absolute color-ffffff background-color-0f112a border-radius-48 font-family-oxanium font-size-1-8vw font-weight-900"
      >
        {{ count }}
      </div>
    </div>
  </div>
  <div class="fourth-right-mobile">
    <div class="fourth-item-text-div column-gap-16px-div">
      <p
        class="font-family-oxanium font-weight-900 color-0f112a font-size-1-4vw"
      >
        {{ title }}
      </p>
      <p
        class="font-family-plus-jakarta font-size-1vw color-6a7386 font-weight-500 line-height-1-8"
      >
        {{ explanation }}
      </p>
    </div>
    <div class="relative">
      <img :src="iconSrc" class="fourth-image-mobile" />
      <div
        class="number-div absolute color-ffffff background-color-0f112a border-radius-48 font-family-oxanium font-size-1-8vw font-weight-900"
      >
        {{ count }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    explanation: String,
    iconSrc: String,
    count: String,
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.fourth-right-item-div {
  width: 100%;
  justify-content: end;
  display: flex;
  flex-direction: row;
  column-gap: 10%;
  align-items: center;
  position: relative;
  height: 100%;
}

.fourth-image {
  width: 20vw;
}
.number-div {
  left: -24%;
  bottom: 70px;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fourth-item-text-div {
  width: 27%;
}
.fourth-right-mobile {
  display: none;
}
@media (max-width: 900px) {
  .fourth-right-item-div {
    display: none;
  }
  .fourth-right-mobile {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 12px;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .fourth-image-mobile {
    width: 100%;
  }
  .number-div {
    bottom: -25px;
    left: 85px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fourth-item-text-div {
    width: 100%;
  }
}
</style>
