<template>
  <div class="footer-div column-gap-8px-div">
    <div class="column-gap-space-between">
      <img class="logo" src="../assets/images/logo-white.png" alt="" />
    </div>
    <div class="line"></div>
    <div class="column-gap-space-between">
      <div class="row-gap-8px-div">
        <img src="../assets/icons/c.svg" style="width: 12px" />
        <p
          class="font-family-plus-jakarta font-size-0-9vw color-c7cbd1 font-weight-500"
        >
          2024 Annot8r
        </p>
      </div>
      <div class="row-gap-16px-div">
        <button class="button button-white-small-circle">
          <img
            class="icon-new"
            src="../assets/icons/discord-white.svg"
            alt=""
          />
        </button>
        <button class="button button-white-small-circle">
          <img
            class="icon-new"
            src="../assets/icons/telegram-white.svg"
            alt=""
          />
        </button>
        <button class="button button-white-small-circle">
          <img
            class="icon-new"
            src="../assets/icons/twitter-white.svg"
            alt=""
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url(../assets/css/styles.css);
.footer-div {
  box-sizing: border-box;
  padding: 12px 40px;
  width: 100vw;
}
.column-gap-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logo {
  height: 24px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(15, 17, 42, 0.1);
}
.icon-new {
  width: 24px;
}
@media (max-width: 900px) {
  .line {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .footer-div {
    padding: 16px;
  }
}
</style>
