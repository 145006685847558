<template>
  <div class="third-div">
    <div
      class="third-text-container-div background-color-0f112a border-radius-48 border-box align-start"
    >
      <p
        class="color-ffffff font-family-oxanium font-weight-900 font-size-5vw upper-text"
      >
        $Anot
      </p>
      <p
        class="font-family-plus-jakarta font-weight-700 font-size-1-8vw color-ffffff line-height-1-8"
      >
        Invest in $ANOT token today and be part of the future of Artificial
        Intelligence.
      </p>
      <p
        class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-1vw line-height-1-8"
      >
        Unlock the potential of earning from anywhere in the world with
        Annot8r’s pioneering income model. Dive into the realm of data
        annotation and amass
        <span class="color-ffffff font-weight-600">$ANOT</span> tokens, the
        lifeblood of our decentralized ecosystem. The more you annotate, the
        more you earn—empowering you to transform your expertise and effort into
        tangible rewards.
      </p>
      <p
        class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-1vw line-height-1-8"
      >
        Annot8r invites you to be part of a futuristic, global community where
        your contributions not only shape cutting-edge of AI but also pave your
        path to borderless financial freedom.
      </p>
    </div>

    <div class="column-gap-32px-div">
      <img
        class="third-page-image border-radius-48 width-100"
        src="../assets/images/third-page-image.png"
        alt=""
      />
      <button
        class="button button-purple color-ffffff width-100"
        style="max-width: 100%; justify-content: space-between; height: 95px"
        @click="scrollToLastSection"
      >
        register for pre-sale
        <img class="icon" src="../assets/icons/arrow-right-bold.svg" />
      </button>
    </div>
  </div>
  <div class="third-div-mobile">
    <div class="mobile-text-div">
      <p
        class="color-ffffff font-family-oxanium font-weight-900 font-size-5vw upper-text"
      >
        $Anot
      </p>
      <p
        class="font-family-plus-jakarta font-weight-700 font-size-1-8vw color-ffffff line-height-1-8"
      >
        Invest in $ANOT token today and be part of the future of Artificial
        Intelligence.
      </p>
      <p
        class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-0-9vw line-height-1-8"
      >
        Unlock the potential of earning from anywhere in the world with
        Annot8r’s pioneering income model. Dive into the realm of data
        annotation and amass
        <span class="color-ffffff font-weight-600">$ANOT</span> tokens, the
        lifeblood of our decentralized ecosystem. The more you annotate, the
        more you earn—empowering you to transform your expertise and effort into
        tangible rewards.
      </p>
      <p
        class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-0-9vw line-height-1-8"
      >
        Annot8r invites you to be part of a futuristic, global community where
        your contributions not only shape cutting-edge of AI but also pave your
        path to borderless financial freedom.
      </p>
    </div>
    <button
      class="button button-purple color-ffffff width-100"
      style="max-width: 100%; justify-content: space-between; height: 65px"
      @click="scrollToLastSection"
    >
      register for pre-sale
      <img class="icon" src="../assets/icons/arrow-right-bold.svg" />
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToLastSection() {
      this.$emit("scroll-to-last-section");
    },
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);
.third-div {
  height: 100vh;
  padding: 80px 40px;
  display: grid;
  grid-template-columns: 8.5fr 5.75fr;
  column-gap: 32px;
  box-sizing: border-box;
  align-items: stretch;
}
.third-text-container-div {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  justify-content: center;
  height: 100%;
}
.third-page-image {
  object-fit: cover;
  height: 70vh;
}
.third-div-mobile {
  display: none;
}
@media (max-width: 900px) {
  .third-div {
    display: none;
  }
  .third-div-mobile {
    width: 100%;
    height: 90vh;
    box-sizing: border-box;
    display: flex;
    padding: 24px;
    flex-direction: column;
    row-gap: 32px;
  }
  .mobile-text-div {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background-color: #0f112a;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: start;
  }
}
</style>
