<template>
  <div class="fourth-div">
    <FourthRightItem
      :title="'Annotation Process'"
      :explanation="'Annotator network reviews and accepts the tasks based on their preferences and label the data according to the task creator’s requirements using specialized tools provided by the Anot8r platform.'"
      :count="'4'"
      :iconSrc="image4"
    />
    <FourthLeftItem
      :title="'Validation'"
      :explanation="'Validators review the labeled data to ensure its accuracy before sending off to in-house master validators of Annot8r for final approval.'"
      :count="'5'"
      :iconSrc="image5"
      :isLine="true"
    />
  </div>
</template>

<script>
import FourthLeftItem from "@/components/FourthLeftItem.vue";
import FourthRightItem from "@/components/FourthRightItem.vue";

export default {
  components: {
    FourthLeftItem,
    FourthRightItem,
  },
  data() {
    return {
      image4: require("@/assets/images/four-four.png"),
      image5: require("@/assets/images/four-five.png"),
    };
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.fourth-div {
  height: 100vh;
  padding: 60px;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0px;
  align-items: center;
}
</style>
