<template>
  <div class="fourth-div">
    <FourthRightItem
      :title="'Task Creation'"
      :explanation="'Task creators create annotation tasks outlining the labeling requirements for the data set they upload or purchase from the Anot8r Data Marketplace, then set their project rewards and deposit $ANOT tokens.'"
      :count="'2'"
      :iconSrc="image2"
    />
    <FourthLeftItem
      :title="'Task Assignment'"
      :explanation="'The platform notifies the Annotator network of the new task based on their skills and availability.'"
      :count="'3'"
      :iconSrc="image3"
    />
  </div>
</template>

<script>
import FourthLeftItem from "@/components/FourthLeftItem.vue";
import FourthRightItem from "@/components/FourthRightItem.vue";

export default {
  components: {
    FourthLeftItem,
    FourthRightItem,
  },
  data() {
    return {
      image2: require("@/assets/images/four-two.png"),
      image3: require("@/assets/images/four-three.png"),
    };
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.fourth-div {
  height: 100vh;
  padding: 60px;
  box-sizing: border-box;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0px;
  align-items: center;
}
</style>
