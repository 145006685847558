<template>
  <div class="fourth-div">
    <p class="font-family-oxanium font-weight-900 font-size-3vw color-0f112a">
      Unveiling the Mechanics: A Guide to Annot8r's Operational Blueprint
    </p>
    <p
      class="font-family-plus-jakarta font-size-1vw color-6a7386 font-weight-500 line-height-1-8"
    >
      Discover how our revolutionary platform combines blockchain, smart
      contracts, and AI to redefine data annotation. Step into a world where
      your contributions fuel AI advancements, empowered by a seamless, secure
      ecosystem. Embrace the future where innovation, precision, and
      collaboration converge at Annot8r.
    </p>

    <FourthLeftItem
      class="item"
      :title="'Data Provisioning'"
      :explanation="'Data Providers upload their unlabeled data to the Annot8r platform. This data can include images, videos, or text relevant to specific projects. Data Providers will set their price in $ANOT for their dataset to be listed on the Anot8r Data Marketplace.'"
      :count="'1'"
      :iconSrc="image1"
    />
  </div>

  <div class="fourth-mobile">
    <p
      class="font-family-oxanium font-size-2vw width-90 font-weight-900 color-0f112a line-height-1-3"
    >
      Unveiling the Mechanics: A Guide to Annot8r's Operational Blueprint
    </p>
    <p
      class="font-family-plus-jakarta font-weight-500 color-6a7386 font-size-1vw line-height-1-8"
    >
      Discover how our revolutionary platform combines blockchain, smart
      contracts, and AI to redefine data annotation. Step into a world where
      your contributions fuel AI advancements, empowered by a seamless, secure
      ecosystem. Embrace the future where innovation, precision, and
      collaboration converge at Annot8r.
    </p>

    <FourthLeftItem
      class="item"
      :title="'Data Provisioning'"
      :explanation="'Data Providers upload their unlabeled data to the Annot8r platform. This data can include images, videos, or text relevant to specific projects. Data Providers will set their price in $ANOT for their dataset to be listed on the Anot8r Data Marketplace.'"
      :count="'1'"
      :iconSrc="image1"
    />
  </div>
</template>

<script>
import FourthLeftItem from "@/components/FourthLeftItem.vue";

export default {
  components: {
    FourthLeftItem,
  },
  data() {
    return {
      image1: require("@/assets/images/four-one.png"),
    };
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.fourth-div {
  height: 100vh;
  padding: 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: start;
  justify-content: start;
  overflow: hidden;
}
.item {
  margin-top: 64px;
}
.fourth-mobile {
  display: none;
}

@media (max-width: 900px) {
  .fourth-div {
    display: none;
  }
  .fourth-mobile {
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 24px;
    flex-direction: column;
    row-gap: 24px;
    margin-top: 90px;
  }
}
</style>
