<template>
  <div
    class="first-background-div background-color-0f112a grid-2-div border-box align-center"
  >
    <div class="column-gap-32px-div width-90">
      <p
        class="title font-weight-900 font-size-5vw font-family-oxanium padding-0 margin-0 color-ffffff line-height-1-3"
      >
        Revolutionizing Data Annotation Marketplace
      </p>
      <p
        class="explanation font-weight-500 font-size-1vw font-family-plus-jakarta margin-0 padding-0 color-c7cbd1 line-height-1-8"
      >
        Annot8r revolutionizes the future of machine learning and computer
        vision AI projects by harnessing the power of a decentralized data
        annotation marketplace. Elevate your AI endeavors with unparalleled
        accuracy and innovation, connecting visionaries and experts across the
        globe in a secure, dynamic ecosystem.
      </p>
      <div class="buttons row-gap-24px-div">
        <button
          class="button button-purple color-ffffff"
          @click="scrollToLastSection"
        >
          join our waitlist
          <img class="icon" src="../assets/icons/arrow-right-bold.svg" />
        </button>
        <button
          class="button button-white color-ffffff background-blur"
          @click="scrollToSecondSection"
        >
          explore
        </button>
      </div>
    </div>
    <div class="image-container width-100">
      <img
        src="../assets/images/first-page-image.png"
        class="image first-page-image absolute border-radius-32"
      />
    </div>
  </div>

  <div class="mobile-div">
    <img
      class="mobile-image width-100"
      src="../assets/images/mobile-first-background.png"
      alt=""
    />
    <div class="inside-div column-gap-32px-div">
      <p class="font-size-5vw font-family-oxanium font-weight-900 color-ffffff">
        Revolutionizing Data Annotation Marketplace
      </p>

      <p
        class="font-family-plus-jakarta color-ffffff font-size-1vw line-height-1-8 color-c7cbd1"
      >
        Annot8r revolutionizes the future of machine learning and computer
        vision AI projects by harnessing the power of a decentralized data
        annotation marketplace. Elevate your AI endeavors with unparalleled
        accuracy and innovation, connecting visionaries and experts across the
        globe in a secure, dynamic ecosystem.
      </p>

      <div class="buttons-mobile row-gap-16px-div">
        <button
          class="button button-purple color-ffffff"
          @click="scrollToLastSection"
        >
          join our waitlist
          <img class="icon" src="../assets/icons/arrow-right-bold.svg" />
        </button>
        <button
          class="button button-white color-ffffff background-blur"
          @click="scrollToSecondSection"
        >
          explore
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToLastSection() {
      this.$emit("scroll-to-last-section");
    },
    scrollToSecondSection() {
      this.$emit("scroll-to-second-section");
    },
  },
  mounted() {
    setTimeout(() => {
      const title = document.querySelector(".title");
      const explanation = document.querySelector(".explanation");
      const buttons = document.querySelector(".buttons");
      title.style.transition = "transform 1s ease-in-out";
      title.style.transform = "translateY(0)";
      explanation.style.transition = "transform 1s ease-in-out";
      explanation.style.transform = "translateY(0)";
      buttons.style.transition = "transform 1s ease-in-out";
      buttons.style.transform = "translateY(0)";
      setTimeout(() => {
        const title = document.querySelector(".title");
        const explanation = document.querySelector(".explanation");
        const buttons = document.querySelector(".buttons");
        const image = document.querySelector(".image");
        let opacity = 0;
        const fadeInInterval = setInterval(() => {
          opacity += 0.05;
          title.style.opacity = opacity;
          explanation.style.opacity = opacity;
          buttons.style.opacity = opacity;
          image.style.opacity = opacity;
          if (opacity >= 1) {
            clearInterval(fadeInInterval);
          }
        }, 50);
      }, 10);
    }, 10);
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);
.first-background-div {
  width: 100%;
  height: 100vh;
  padding: 40px;
}
.image-container {
  width: 100%;
}
.first-page-image {
  bottom: 40px;

  top: 20px;
  width: 50vw;
  height: 80vh;
  object-fit: cover;
  object-position: bottom;
  position: relative;
}
.title,
.explanation,
.buttons {
  transition: all 10s ease-out;
  transform: translateY(50px);
  opacity: 0;
}
.image {
  transition: all 1s ease-out;
  opacity: 0;
}
.mobile-div {
  display: none;
}

@media (max-width: 900px) {
  .mobile-div {
    display: block;
    width: 100%;
    height: 100vh;
    background-color: #0f112a;
    position: relative;
    box-sizing: border-box;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .first-background-div {
    display: none;
  }
  .mobile-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .inside-div {
    position: relative;
  }
  .buttons-mobile {
    position: relative;
  }
}
</style>
