<template>
  <div class="fifth-div">
    <div class="fifth-container-div border-radius-48 border-box align-start">
      <div class="fifth-left-div column-gap-24px-div">
        <p
          class="color-ffffff font-family-oxanium font-weight-900 font-size-4vw"
        >
          Our whole ecosystem runs on $ANOT
        </p>
        <p
          class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-1vw line-height-1-8"
        >
          Upon approval of labeling accuracy from our master validators,
          Annotators and Validators receive compensation in
          <span class="color-ffffff font-weight-600">$ANOT</span> , Annot8r’s
          proprietary cryptocurrency, ensuring a seamless, transparent, and
          secure transaction within the marketplace.
        </p>
        <p
          class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-1vw line-height-1-8"
        >
          The <span class="color-ffffff font-weight-600">$ANOT</span> token
          facilitates not only payments but also acts as a reward mechanism,
          incentivizing excellence and engagement within the Annot8r community.
        </p>
        <p
          class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-1vw line-height-1-8"
        >
          This concise, smart contract-driven workflow underpins the Annot8r
          marketplace, ensuring transparency, security, and efficiency from task
          creation to completion. The use of
          <span class="color-ffffff font-weight-600">$ANOT</span> as the
          marketplace currency streamlines transactions, rewarding participants
          and fostering a vibrant, collaborative ecosystem.
        </p>
      </div>
    </div>
  </div>
  <div class="fifth-mobile">
    <p class="color-ffffff font-family-oxanium font-weight-900 font-size-5vw">
      Our whole ecosystem runs on $ANOT
    </p>
    <p
      class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-0-9vw line-height-1-8"
    >
      Upon approval of labeling accuracy from our master validators, Annotators
      and Validators receive compensation in $ANOT, Annot8r’s proprietary
      cryptocurrency, ensuring a seamless, transparent, and secure transaction
      within the marketplace.
    </p>
    <p
      class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-0-9vw line-height-1-8"
    >
      The $ANOT token facilitates not only payments but also acts as a reward
      mechanism, incentivizing excellence and engagement within the Annot8r
      community.
    </p>
    <p
      class="font-family-plus-jakarta font-weight-500 color-c7cbd1 font-size-0-9vw line-height-1-8"
    >
      This concise, smart contract-driven workflow underpins the Annot8r
      marketplace, ensuring transparency, security, and efficiency from task
      creation to completion. The use of $ANOT as the marketplace currency
      streamlines transactions, rewarding participants and fostering a vibrant,
      collaborative ecosystem.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url(../assets/css/styles.css);
.fifth-div {
  width: 100%;
  height: 100vh;
  padding: 80px 40px;
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 32px;
  box-sizing: border-box;
}
.fifth-container-div {
  background-image: url(../assets/images/fifth-page-image.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  row-gap: 32px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fifth-left-div {
  padding: 70px 60px;
}
.image {
  width: 100%;
  border-radius: 0 0 48px 0;
  position: absolute;
  right: 0;
  bottom: 0;
}
.fifth-mobile {
  display: none;
}
@media (max-width: 900px) {
  .fifth-div {
    display: none;
  }
  .fifth-mobile {
    display: flex;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 24px;
    margin-top: 40px;
    background-image: url(../assets/images/fifth-page-mobile.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    width: 90%;
    border-radius: 32px;
    height: 85vh;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    justify-content: center;
  }
}
</style>
