<template>
  <div class="sixth-div">
    <div class="sixth-inside-div background-color-d4d4d6">
      <div class="sixth-text-div column-gap-24px-div">
        <p
          class="font-family-plus-jakarta font-weight-500 color-6a7386 line-height-1-8 font-size-1-4vw"
          style="font-style: italic"
        >
          Join Annot8r and become part of a pioneering community that's setting
          new standards for data annotation.
        </p>
        <p
          class="font-family-plus-jakarta font-weight-500 color-6a7386 line-height-1-8 font-size-1-4vw"
          style="font-style: italic"
        >
          Together, we're not just processing data; we're unlocking the full
          potential of AI and opening a new frontier for global employment, one
          annotation at a time.
        </p>
        <p
          class="font-family-plus-jakarta font-weight-700 color-6a7386 line-height-1-8 font-size-2vw"
          style="font-style: italic"
        >
          Welcome to the forefront of innovation.
        </p>
      </div>
    </div>
    <img
      class="sixth-image"
      src="../assets/images/sixth-page-image.svg"
      alt=""
    />
  </div>
  <div class="sixth-div-mobile">
    <div class="mobile-inside">
      <div class="sixth-text-div column-gap-24px-div">
        <p
          class="font-family-plus-jakarta font-weight-500 color-6a7386 line-height-1-8 font-size-1vw"
          style="font-style: italic"
        >
          Join Annot8r and become part of a pioneering community that's setting
          new standards for data annotation.
        </p>
        <p
          class="font-family-plus-jakarta font-weight-500 color-6a7386 line-height-1-8 font-size-1vw"
          style="font-style: italic"
        >
          Together, we're not just processing data; we're unlocking the full
          potential of AI and opening a new frontier for global employment, one
          annotation at a time.
        </p>
        <p
          class="font-family-plus-jakarta font-weight-700 color-6a7386 line-height-1-8 font-size-2vw"
          style="font-style: italic"
        >
          Welcome to the forefront of innovation.
        </p>
      </div>
      <img
        class="sixth-image-mobile"
        src="../assets/images/sixth-mobile.svg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.sixth-div {
  height: 100vh;
  padding: 100px 0;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
}
.sixth-inside-div {
  width: 100%;
  height: 100%;
  display: flex;

  align-items: center;
  justify-content: start;
}
.sixth-image {
  position: absolute;
  right: 120px;
  height: 90vh;
}
.sixth-text-div {
  box-sizing: border-box;
  padding: 140px;
  width: 64%;
}
.sixth-div-mobile {
  display: none;
}
@media (max-width: 900px) {
  .sixth-div {
    display: none;
  }

  .sixth-div-mobile {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eaeaea;
  }
  .mobile-inside {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #d4d4d7;
  }
  .sixth-text-div {
    width: 100%;
    padding: 24px;
  }
  .sixth-image-mobile {
    position: absolute;
    width: 90%;
    bottom: -24px;
  }
}
</style>
