<template>
  <div class="second-page-item-div border-radius-32 relative">
    <div class="circle border-radius-32 align-center">
      <img class="icon" :src="iconSrc" alt="" />
    </div>
    <p
      class="second-page-title font-family-oxanium font-size-1-2vw font-weight-700 color-0f112a upper-text"
    >
      {{ title }}
    </p>

    <p
      class="second-page-item-exp font-family-plus-jakarta font-size-0-9vw color-c7cbd1 font-weight-500 absolute line-height-1-8"
    >
      {{ explanation }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    explanation: String,
    iconSrc: String,
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.second-page-item-div {
  height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 24px;
  transition: all 0.35s ease-out;
  background-color: #e2e2e3;
}
.second-page-item-div:hover {
  background-color: #0f112a;
}
.second-page-title {
  position: absolute;
  top: 128px;
  box-sizing: border-box;
  padding: 0 20px;
  text-align: center;
  transition: all 0.35s ease-out;
}
.circle {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  background-color: #d4d4d6;
  position: absolute;
  transition: all 0.35s ease-out;
  top: 32px;
  z-index: 2;
}
.second-page-item-exp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  height: auto;
  transition: all 0.35s ease-out;
  width: 80%;
}
.second-page-item-div:hover .second-page-item-exp {
  opacity: 1;
}
.second-page-item-div:hover .circle {
  background-color: #ffffff;
  position: absolute;
  top: -32px;
}
.second-page-item-div:hover .second-page-title {
  opacity: 0;
  top: 0;
}
@media (min-width: 901px) and (max-width: 1200px) {
  .circle {
    width: 56px;
    height: 56px;
  }
}
</style>
