<template>
  <div class="footer-div column-gap-8px-div">
    <div class="column-gap-space-between">
      <img class="logo" src="../assets/images/logo-black.svg" alt="" />
    </div>
    <div class="line"></div>
    <div class="column-gap-space-between">
      <div class="row-gap-8px-div">
        <div class="row-gap-8px-div" style="column-gap: 0px">
          <p
            class="font-family-plus-jakarta font-size-0-9vw color-6a7386 font-weight-500"
          >
            2024
          </p>
          <img src="../assets/icons/c.svg" style="width: 12px" />
        </div>

        <p
          class="font-family-plus-jakarta font-size-0-9vw color-6a7386 font-weight-500"
        >
          Annot8r. All rights reserved
        </p>
      </div>
      <div class="row-gap-16px-div">
        <button class="button button-black-small-circle">
          <img class="icon-new" src="../assets/icons/discord.svg" alt="" />
        </button>
        <button class="button button-black-small-circle">
          <img class="icon-new" src="../assets/icons/telegram.svg" alt="" />
        </button>
        <button class="button button-black-small-circle">
          <img class="icon-new" src="../assets/icons/twitter.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@import url(../assets/css/styles.css);
.footer-div {
  box-sizing: border-box;
  padding: 12px 40px;
  width: 100%;
}
.column-gap-space-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logo {
  height: 24px;
}
.line {
  width: 100%;
  height: 1px;
  background-color: rgba(15, 17, 42, 0.1);
}
.icon-new {
  width: 20px;
}
</style>
