<template>
  <div class="second-background-div">
    <div class="grid-2-div align-top">
      <p
        class="font-family-oxanium font-size-2vw width-90 font-weight-900 color-0f112a line-height-1-3"
      >
        Unlocking the future of Data Annotation: The transformative power of
        Annot8r's Decentralized ecosystem.
      </p>
      <p
        class="font-family-plus-jakarta font-weight-500 color-6a7386 font-size-1vw line-height-1-8"
      >
        Embark on a pioneering journey with Annot8r, where innovation meets
        precision in the realm of data annotation. At Annot8r, we’re not just
        creating a marketplace; we’re redefining the future of AI model training
        through efficient and secure data labeling through our cutting-edge,
        decentralized platform. Our workflow is designed to empower and reward
        our community of annotators at every step, while ensuring efficiency and
        security for task creators.
      </p>
    </div>
    <div class="grid-4-div">
      <SecondPageItem
        :title="'Secure & Encrypted'"
        :explanation="`Blockchain
      technology and encryption protocols safeguard sensitive data, maintaining
      privacy and integrity throughout the data labeling and validation
      process.`"
        :iconSrc="icon1"
      />
      <SecondPageItem
        :title="'Decentralized'"
        :explanation="`Decentralization enhances Annot8r's efficiency, promoting trust and innovation within its data annotation marketplace, while allowing our Annotator network a borderless income source.`"
        :iconSrc="icon2"
      />
      <SecondPageItem
        :title="'Transparent & Authenticated'"
        :explanation="'Smart contracts ensure transparent and immutable transactions, providing both annotators and project owners with confidence in fair compensation and secure data handling.'"
        :iconSrc="icon3"
      />
      <SecondPageItem
        :title="'Double Layer Quality Assurance'"
        :explanation="'Annot8r maintains high standards of data quality through a network of skilled annotators and rigorous validation processes, ensuring reliable AI model training.'"
        :iconSrc="icon4"
      />
    </div>

    <div class="grid-1-2-div">
      <SecondPageItem
        :title="'Blazing-fast'"
        :explanation="`Annot8r streamlines the entire workflow, reducing administrative overhead and accelerating project timelines by connecting data providers, annotators, project owners, and validators seamlessly.`"
        :iconSrc="icon5"
      />
      <SecondPageItem
        :title="'Scalability'"
        :explanation="'The decentralized nature of the Annot8r marketplace allows for seamless scalability, accommodating projects of any size and complexity while ensuring efficient resource allocation.'"
        :iconSrc="icon6"
      />
      <SecondPageItem
        :title="'User Authentication'"
        :explanation="'Users can securely access the Annot8r marketplace through web-based platforms, with the option to connect their crypto wallets for seamless transactions and login capabilities.'"
        :iconSrc="icon7"
      />
    </div>
  </div>

  <div class="second-mobile">
    <p
      class="font-family-oxanium font-size-2vw width-90 font-weight-900 color-0f112a line-height-1-3"
    >
      Unlocking the future of Data Annotation: The transformative power of
      Annot8r's Decentralized ecosystem.
    </p>
    <p
      class="font-family-plus-jakarta font-weight-500 color-6a7386 font-size-0-9vw line-height-1-8"
    >
      Embark on a pioneering journey with Annot8r, where innovation meets
      precision in the realm of data annotation. At Annot8r, we’re not just
      creating a marketplace; we’re redefining the future of AI model training
      through efficient and secure data labeling through our cutting-edge,
      decentralized platform. Our workflow is designed to empower and reward our
      community of annotators at every step, while ensuring efficiency and
      security for task creators.
    </p>

    <div class="column-gap-8px-div">
      <div class="slider">
        <div class="slide">
          <SecondPageItem
            :title="'Secure & Encrypted'"
            :explanation="`Blockchain
      technology and encryption protocols safeguard sensitive data, maintaining
      privacy and integrity throughout the data labeling and validation
      process.`"
            :iconSrc="icon1"
          />
        </div>
        <div class="slide">
          <SecondPageItem
            :title="'Decentralized'"
            :explanation="`Decentralization enhances Annot8r's efficiency, promoting trust and innovation within its data annotation marketplace, while allowing our Annotator network a borderless income source.`"
            :iconSrc="icon2"
          />
        </div>
        <div class="slide">
          <SecondPageItem
            :title="'Transparent & Authenticated'"
            :explanation="'Smart contracts ensure transparent and immutable transactions, providing both annotators and project owners with confidence in fair compensation and secure data handling.'"
            :iconSrc="icon3"
          />
        </div>
        <div class="slide">
          <SecondPageItem
            :title="'Double Layer Quality Assurance'"
            :explanation="'Annot8r maintains high standards of data quality through a network of skilled annotators and rigorous validation processes, ensuring reliable AI model training.'"
            :iconSrc="icon4"
          />
        </div>
        <div class="slide">
          <SecondPageItem
            :title="'Blazing-fast'"
            :explanation="`Annot8r streamlines the entire workflow, reducing administrative overhead and accelerating project timelines by connecting data providers, annotators, project owners, and validators seamlessly.`"
            :iconSrc="icon5"
          />
        </div>
        <div class="slide">
          <SecondPageItem
            :title="'Scalability'"
            :explanation="'The decentralized nature of the Annot8r marketplace allows for seamless scalability, accommodating projects of any size and complexity while ensuring efficient resource allocation.'"
            :iconSrc="icon6"
          />
        </div>
        <div class="slide">
          <SecondPageItem
            :title="'User Authentication'"
            :explanation="'Users can securely access the Annot8r marketplace through web-based platforms, with the option to connect their crypto wallets for seamless transactions and login capabilities.'"
            :iconSrc="icon7"
          />
        </div>
      </div>
      <div class="dots-container">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </div>
</template>

<script>
import SecondPageItem from "@/components/SecondPageItem.vue";

export default {
  components: {
    SecondPageItem,
  },
  data() {
    return {
      icon1: require("@/assets/icons/secure-dark.svg"),
      icon2: require("@/assets/icons/decentralized.svg"),
      icon3: require("@/assets/icons/transparent.svg"),
      icon4: require("@/assets/icons/double-layer.svg"),
      icon5: require("@/assets/icons/blazing-fast.svg"),
      icon6: require("@/assets/icons/scalability.svg"),
      icon7: require("@/assets/icons/user-authentication.svg"),
    };
  },
  mounted() {
    let slideIndex = 0;
    let startX;
    let dist;
    let threshold = 150; // minimum distance to swipe

    showSlide(slideIndex);

    function showSlide(index) {
      let slides = document.querySelectorAll(".slide");
      let dots = document.querySelectorAll(".dot");
      if (index >= slides.length) {
        slideIndex = 0;
      }
      if (index < 0) {
        slideIndex = slides.length - 1;
      }
      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
        dots[i].classList.remove("active");
      }
      slides[slideIndex].style.display = "block";
      dots[slideIndex].classList.add("active");
    }

    function plusSlide(n) {
      showSlide((slideIndex += n));
    }

    document.querySelector(".slider").addEventListener("touchstart", (e) => {
      let touchobj = e.changedTouches[0];
      startX = touchobj.pageX;
    });

    document.querySelector(".slider").addEventListener("touchmove", (e) => {
      let touchobj = e.changedTouches[0];
      dist = touchobj.pageX - startX;
    });

    document.querySelector(".slider").addEventListener("touchend", (e) => {
      if (Math.abs(dist) >= threshold) {
        plusSlide(dist > 0 ? -1 : 1);
      }
    });
  },
};
</script>

<style scoped>
@import url(../assets/css/styles.css);

.second-background-div {
  height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}
.second-mobile {
  display: none;
}
@media (max-width: 900px) {
  .second-background-div {
    display: none;
  }

  .second-mobile {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    box-sizing: border-box;
    padding: 24px;
    margin-top: 80px;
  }
  .slider-container {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .slider {
    display: flex;
  }

  .slide {
    flex: 0 0 100%;
  }

  .slide img {
    width: 100%;
    height: auto;
  }

  .dots-container {
    text-align: center;
    margin-top: 10px;
  }

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px;
    background-color: #d4d4d6;
    border-radius: 50%;
    cursor: pointer;
  }

  .dot.active {
    background-color: #0f112a;
  }
}
</style>
